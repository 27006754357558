import styled, { ThemeProvider } from 'styled-components';
import Container from '@material-ui/core/Container';
import { StylesProvider, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const PageLayout = styled.div`
  min-height: 100vh;
  width: 100%;
`

const CustomContainer = styled(Container)`
  height: 100%;
  padding-top: 0px;
`;

export default ({ children }: any) => {
  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#2196f3'
      }
    }
  });

  return (
    <PageLayout>
      <StylesProvider>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <CustomContainer disableGutters>
              {children}
            </CustomContainer>
          </MuiThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </PageLayout >
  )
};
